import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import styles from "../styles/components/team-member-hero.module.scss"

import Flag1 from "../images/team-hero-flag-1.svg"
import Flag2 from "../images/team-hero-flag-2.svg"
import Flag3 from "../images/team-hero-flag-3.svg"
import Flag4 from "../images/team-hero-flag-4.svg"

import TwitterIcon from "../images/twitter-icon.svg"
import LinkedinIcon from "../images/linkedin-icon.svg"
import BlogIcon from "../images/blog-icon.svg"

const TeamMemberHero = props => {
  let socialLinks = props.socialLinks ? props.socialLinks : {}
  let { twitter, linkedIn, blog } = socialLinks
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "images/placeholder-headshot.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  //find the correct website for a person based on which company they are a founder of
  let website = null

  if (props.affiliation === "Other" && props.company) {
    props.company.forEach(company => {
      if (company.website && company.founders) {
        company.founders.forEach(founder => {
          if (founder.name === props.name) {
            website = company.website
          }
        })
      }
    })
  }

  return (
    <div className={styles.background}>
      <ReactCSSTransitionGroup
        transitionName="flag-1-person"
        /* add an extra transition phase at the initial mount of the component */
        transitionAppear={true}
        transitionAppearTimeout={600} /* timeout = delay + duration in css */
        transitionEnter={false}
        transitionLeave={false}
      >
        <Flag1 id={styles.flag1} />
      </ReactCSSTransitionGroup>
      <ReactCSSTransitionGroup
        transitionName="flag-2-person"
        transitionAppear={true}
        transitionAppearTimeout={750}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Flag2 id={styles.flag2} />
      </ReactCSSTransitionGroup>
      <ReactCSSTransitionGroup
        transitionName="flag-3-person"
        transitionAppear={true}
        transitionAppearTimeout={2000}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Flag3 id={styles.flag3} />
      </ReactCSSTransitionGroup>
      <ReactCSSTransitionGroup
        transitionName="flag-4-person"
        transitionAppear={true}
        transitionAppearTimeout={750}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Flag4 id={styles.flag4} />
      </ReactCSSTransitionGroup>
      <div className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <div className={styles.name}>{props.name}</div>
          {!props.titleOverride && (
            <div className={styles.title}>
              <span>{props.position}</span>
              <span>{props.companyName}</span>
            </div>
          )}
          {props.titleOverride && (
            <div className={styles.title}>
              {props.titleOverride.titleOverride}
            </div>
          )}
          {props.affiliation === "Flybridge" && (
            <div className={styles.location}>{props.location}</div>
          )}
          {props.shortDescription && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: props.shortDescription.childMarkdownRemark.html,
              }}
            />
          )}
          {props.affiliation === "Flybridge" && (
            <div className={styles.email}>
              <a href={`mailto:${props.email}`}>{props.email}</a>
            </div>
          )}
          {props.affiliation === "Flybridge" && props.socialLinks && (
            <div className={styles.socialIcons}>
              {twitter && (
                <a href={twitter} target="_blank" rel="noopener noreferrer">
                  <TwitterIcon />
                </a>
              )}
              {linkedIn && (
                <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                  <LinkedinIcon />
                </a>
              )}
              {blog && (
                <a href={blog} target="_blank" rel="noopener noreferrer">
                  <BlogIcon />
                </a>
              )}
            </div>
          )}
          {/* Style Location, Website, Social for Founders similar to Company Hero */}
          {props.affiliation === "Other" && (
            <div className={styles.info}>
              <div className={styles.column}>
                {props.location && <div className={styles.label}>Location</div>}
                {props.location && (
                  <div className={styles.value}>{props.location}</div>
                )}
                {props.company && website && (
                  <div className={styles.label}>Website</div>
                )}
                {props.company && website && (
                  <div className={styles.value}>
                    <a href={website} target="_blank" rel="noopener noreferrer">
                      {
                        website
                          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                          .split("/")[0]
                      }
                    </a>
                  </div>
                )}
              </div>
              <div className={styles.column}>
                {props.socialLinks && (
                  <div className={styles.label}>Social</div>
                )}
                {props.socialLinks && (
                  <div className={styles.socialIcons}>
                    {props.socialLinks.twitter && (
                      <a
                        href={props.socialLinks.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TwitterIcon />
                      </a>
                    )}
                    {props.socialLinks.linkedIn && (
                      <a
                        href={props.socialLinks.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedinIcon />
                      </a>
                    )}
                    {blog && (
                      <a href={blog} target="_blank" rel="noopener noreferrer">
                        <BlogIcon />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.logoBox}>
            <Img
              fluid={
                props.fullImage
                  ? props.fullImage.fluid
                  : data.placeholderImage.childImageSharp.fluid
              }
              alt={props.name}
              className={styles.image}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamMemberHero
