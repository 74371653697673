import React from "react"
import { graphql } from "gatsby"
import { removeRepeatPeople } from "../utils/grid-sorting"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TeamMemberHero from "../components/teamMemberHero"
import TeamAboutSection from "../components/teamAboutSection"
import Grid from "../components/grid"
import TextBox from "../components/textBox"
import NewsSection from "../components/newsSection"
import CommunityButton from "../components/communityButton"
import ContactSection from "../components/contactSection"

function mostRecentDate(a, b) {
  const dateA = a.date
  const dateB = b.date

  if (dateA < dateB) return 1
  if (dateA > dateB) return -1
  return 0
}

const TeamMember = props => {
  const person = props.data.contentfulPerson
  const firstName = person.name.split(" ")[0]

  let family = []
  let peopleTracker = {}
  peopleTracker[person.id] = 1
  if (person.company) {
    //sort companies by date field
    const sortedCompanies = person.company.sort(mostRecentDate)
    sortedCompanies.forEach(company => {
      family.push(company)
      family = family.concat(
        removeRepeatPeople(company.founders, peopleTracker)
      )
      family = family.concat(
        removeRepeatPeople(company.investors, peopleTracker)
      )
    })
  }
  //pageContext comes from gatsby-node
  //add Flybridge people to their family if they are a Flybridge team member
  if (person.affiliation === "Flybridge") {
    family = family.concat(
      removeRepeatPeople(props.pageContext.flybridgePeople, peopleTracker)
    )
  }
  return (
    <Layout>
      <SEO
        title={person.name}
        description={
          `${person.position}` + " | " + `${person.shortDescription}`
        }
      />
      <TeamMemberHero {...person} />
      {person.longBio && (
        <TeamAboutSection
          header={`More About ${firstName}`}
          text={person.longBio.childMarkdownRemark.html}
        />
      )}
      {family.length > 0 && (
        <Grid header={`${firstName}'s Community`} tiles={family} />
      )}
      {person.funFacts && (
        <TextBox
          header="Some Fun Facts"
          textBlockList={person.funFacts.textBlockList}
        />
      )}
      {person.newsThoughts && (
        <NewsSection
          title="Latest News & Thoughts"
          news={person.newsThoughts}
        />
      )}
      <CommunityButton />
      <ContactSection />
    </Layout>
  )
}

export default TeamMember

export const query = graphql`
  query TeamMemberBySlug($slug: String!) {
    contentfulPerson(slug: { eq: $slug }) {
      name
      companyName
      affiliation
      id
      fullImage {
        fluid(maxWidth: 464, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      position
      titleOverride {
        titleOverride
      }
      location
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      email
      socialLinks {
        twitter
        linkedIn
        blog
      }
      longBio {
        childMarkdownRemark {
          html
        }
      }
      funFacts {
        textBlockList {
          title
          body {
            body
          }
        }
      }
      newsThoughts {
        source
        description
        link
      }
      company {
        website
        ...CompanyTile
        founders {
          ...PersonTile
        }
        investors {
          ...PersonTile
        }
      }
    }
  }
`
