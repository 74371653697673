import React from "react"
import Reveal from "react-reveal/Reveal"
import styles from "../styles/components/team-about-section.module.scss"

class TeamAboutSection extends React.Component {
  componentDidMount() {
    var anchors = document
      .getElementsByClassName(styles.text)[0]
      .getElementsByTagName("a")
    for (var i = 0; i < anchors.length; i++) {
      anchors[i].setAttribute("target", "_blank")
      anchors[i].setAttribute("rel", "noopener noreferrer")
    }
  }
  render() {
    return (
      <div className={styles.container}>
        <Reveal effect="fadeIn" duration={400}>
          <div className={styles.header}>{this.props.header}</div>
        </Reveal>
        <Reveal effect="fadeIn" duration={400}>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: this.props.text }}
          />
        </Reveal>
      </div>
    )
  }
}

export default TeamAboutSection
