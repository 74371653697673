// Grid sorting functions that are repeated and used in gatsby-node

function compareLastName(a, b) {
  let lastNameA = a.name.split(" ")
  let lastNameB = b.name.split(" ")
  lastNameA = lastNameA[lastNameA.length - 1]
  lastNameB = lastNameB[lastNameB.length - 1]

  if (lastNameA < lastNameB) return -1
  if (lastNameA > lastNameB) return 1
  return 0
}

/**
 * Returns an array of people objects, with people who are already in peopleTracker removed
 * However, people with "Other" affiliation (aka founders) can be repeated
 * @param {Array} people people Objects to check against peopleTracker
 * @param {Object} peopleTracker object that tracks which people have already been seen
 *                               in the form {people.id : 1} if the person has been seen
 * @param {Boolean} repeatFounders founders can be repeated
 */
function removeRepeatPeople(people, peopleTracker, repeatFounders) {
  let newPeople = []
  if (people && people[0]) {
    people.forEach(person => {
      if (repeatFounders) {
        if (person.affiliation !== "Other") {
          if (peopleTracker[person.id] === undefined) {
            peopleTracker[person.id] = 1
            newPeople.push(person)
          }
        } else newPeople.push(person) //people with "Other" affiliation (aka founders) can be repeated
      } else {
        if (peopleTracker[person.id] === undefined) {
          peopleTracker[person.id] = 1
          newPeople.push(person)
        }
      }
    })
  }
  return newPeople
}

const isFlybridgeCompany = company => {
  //console.log(company.name, "IS FLYBRIDGE COMPANY?")
  let isFlybridgeCompany = true
  //console.log("filters", company.filters)
  if (company.filters) {
    const slugs = company.filters.map(filter => filter.slug)
    //console.log("slugs", slugs)
    if (
      (slugs.includes("the-graduate-syndicate") ||
        slugs.includes("xfactor-ventures")) &&
      !slugs.includes("flybridge")
    ) {
      isFlybridgeCompany = false
    }
  }
  //console.log(isFlybridgeCompany)
  return isFlybridgeCompany
}

const getFirstRows = (companies, numRows) => {
  let peopleTracker = {}
  let personCount = 0
  let i = 0
  let firstRows = []

  while (personCount < 5 * numRows) {
    // 5 people in a row
    const company = companies[i]
    personCount += removeRepeatPeople(company.founders, peopleTracker).length
    personCount += removeRepeatPeople(company.investors, peopleTracker).length
    if (personCount >= 5 * numRows) {
      break
    } else {
      firstRows.push(company)
      i++
    }
  }
  return firstRows
}

module.exports = {
  compareLastName,
  removeRepeatPeople,
  isFlybridgeCompany,
  getFirstRows,
}
